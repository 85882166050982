import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { D1, P, H4 } from "../components/utils/Typography"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"

import { ButtonPrimary } from "../components/utils/Buttons"
import * as ReactMarkdown from "react-markdown"
import OnDeltaCard from "../components/Website-Blocks/OnDeltaCard"
import Email from "../components/Website-Blocks/Email"
import List from "../components/Listings/List"

const HeroCover = styled.div`
  background-color: #12141678;
  height: 60vh;
  padding: 160px 80px;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
  position: relative;
`

const Hero = styled.div`
  height: 60vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const Header = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 800px;
  padding: 20px;
`

const Tag = styled.div`
  background-color: #0022e721;
  padding: 10px;
  margin: 10px;
`

const Content = styled.div`
  margin: 30px 30px 70px 30px;
`

const JobBoard = styled.div`
  margin: 70px 0 70px 0;
`

const BlogPost = ({ data }) => {
  const { title, body, image, tags, author, jobCategory } = data.contentfulBlog

  return (
    <Layout>
      <SEO title={title} />
      <Hero style={{ backgroundImage: `url(${image.file.url})` }}>
        <HeroCover>
          <Header>
            <D1 style={{ color: "white" }}>{title}</D1>

            <H4
              style={{
                fontWeight: 800,
                color: "white",
                marginTop: "20px",
                textAlign: "center",
              }}
            >
              Written by {author}
            </H4>
          </Header>
        </HeroCover>
      </Hero>
      <Container>
        <Row>
          <Col lg="9">
            <Content>
              <ReactMarkdown source={body.body} />
              <JobBoard>
                <List filterStartState={jobCategory} />
              </JobBoard>
            </Content>
            <Row style={{ margin: "30px 0 50px" }}>
              <Col sm="6" xs="7" lg="6">
                <Row>
                  {tags.map(tag => (
                    <Tag>
                      <span key={tag}>
                        <P style={{ margin: "0", fontWeight: "600" }}>#{tag}</P>
                      </span>
                    </Tag>
                  ))}
                </Row>
              </Col>
              <Col sm="6" xs="5" lg="6" style={{ textAlign: "right" }}>
                <Link to="/blogposts">
                  <ButtonPrimary>Go to Posts</ButtonPrimary>{" "}
                </Link>
              </Col>
            </Row>
            <Email />
          </Col>
          <Col lg="3">
            <OnDeltaCard />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
export default BlogPost

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulBlog(slug: { eq: $slug }) {
      title
      author
      slug
      jobCategory
      body {
        body
      }
      image {
        file {
          url
        }
      }
      tags
    }
  }
`
